<template>
  <section>
      <div class="content-header">
        <h2>Helping students embrace and learn from failure</h2>
      </div>
      <div class="content-wrapper">
        <p>Failure is a critical component of learning; however, students often view failure in a negative light. </p>
        <p>How can we encourage students to see the value of failure, learn how to fail well and become resilient learners? </p>
        <p>This <a href="https://www.youtube.com/watch?v=BmAasU9U66Q" target="_blank">recorded lecture</a> by Fiona Rawle, PhD, Associate Dean at the University of Toronto explores the literature on productive failure and resiliency-building approaches in academia. </p>
        <p>It also dives into a novel course reframing called FLIP (Failure: Learning in Progress). The purpose of the FLIP course reframing project is to:</p>
        <ol>
          <li>Establish instructional tools and approaches for teaching students to embrace and learn from failure</li>
          <li>Develop both formative and summative assessment methods for failure bounce back</li>
          <li>Create a resource of failure narratives that can be used in courses across disciplines. </li>
        </ol>
        <p>In addition, Dr. Rawle explores how online instruction may offer additional opportunities for the teaching of failure and resiliency.</p>
      </div>
  </section>
</template>

<script>
// import Accordion from '../components/Accordion.vue'
// import SidebarMenu from '../components/SidebarMenu.vue'
// import {
//   Hooper,
//   Slide,
//   Navigation as HooperNavigation,
//   Pagination as HooperPagination
// } from 'hooper'
// import 'hooper/dist/hooper.css'

export default {
  name: '03',
  components: {
    // SidebarMenu,
    // Accordion,
    // Hooper,
    // Slide,
    // HooperNavigation,
    // HooperPagination
  },
  data () {
    return {
    }
  },

  mounted () {
  }
}
</script>

<style>

</style>
